<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 新增停售产品单</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <div class="flex">
          <div> 
            <el-form-item label="停售日期：" prop="halt_sale_date">
              <el-date-picker v-model="form.halt_sale_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:300px">
              </el-date-picker>
            </el-form-item>
            <el-form-item :label="!form.id?'单据编号前缀：':'单据编号：'" prop="no">
                <el-select v-model="form.no" v-if="!form.id" placeholder="请选择"
                    style="width: 100%;; margin-right: 15px;" filterable>
                    <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                        :key="index" :value="item.value"></el-option>
                </el-select>
                <el-input v-if="form.id" disabled style="width: 100%;" v-model="form.no"
                    placeholder="请输入单据编号" />
            </el-form-item>
            <el-form-item label="检测人：" prop="test_id">
              <el-select v-model="form.test_id" placeholder="请选择"
                  style="width: 100%;; margin-right: 15px;" filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in rylist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经办人：" prop="handle_id">
              <el-select v-model="form.handle_id" placeholder="请选择"
                  style="width: 100%;; margin-right: 15px;" filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in jblist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            
          </div>
        </div>

        <div class="table_Box">
          <div class="table_title">
            停售产品： <span @click="sele_products">选择产品</span>
          </div>
          <el-table :data="have_sele_arr" style="width: 100%">
            <el-table-column prop="product_no" label="产品编号" />
            <el-table-column prop="product_name" label="品名" />
            <el-table-column prop="model" label="型号" />
            <el-table-column prop="mnemonic_code" label="助记码" />
            <el-table-column prop="model" label="型号规格" />
            <el-table-column prop="manufacturer" label="生产厂家" />
            <el-table-column prop="pack_unit_name" label="包装单位" />
            
            <el-table-column fixed="right" label="操作">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        
        <el-form-item label="停售标识：">
          <el-radio-group v-model="form.type" @change="change_type($event)">
            <el-radio label="2">所有批次停售</el-radio>
            <el-radio label="1">当前批次停售否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="当前批次：" prop="batch_number"  v-if="form.type==1">
            <el-select v-model="form.batch_number" placeholder="请选择"
                style="width: 300px; margin-right: 15px;" filterable>
                <el-option :label="item.batch_number" v-for="(item,index) in phlist"
                    :key="index" :value="item.batch_number"></el-option>
            </el-select>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
  <selectProducts ref="selectProducts" @after_sele="after_sele_Products" />
</template>

<script>
export default {
  name: "addpurchasePlan",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
         type:'2'
      },
      rules: {
        halt_sale_date: [
          { required: true, message: "停售日期不能为空",trigger: "blur"}
        ],
        no: [
          { required: true, message: "编号前缀不能为空",trigger: "blur"}
        ],
        test_id: [
          { required: true, message: "检测人不能为空",trigger: "blur"}
        ],
        handle_id: [
          { required: true, message: "经办人不能为空",trigger: "blur"}
        ],
        batch_number: [
          { required: false, message: "当前批次不能为空",trigger: "blur"}
        ]
      },
      zjlist:[],
      list:[],
      rylist:[],
      hwlist:[],
      // 已选择产品
      have_sele_arr:[],
      phlist:[],
      jblist:[]
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.get_data()
      }else{
          this.form.halt_sale_date=this.today_val()
      }
      this.getOptions();
  },
  methods: {
      change_type(e){
         if(e==1){
            this.rules.batch_number[0].required=true
         }else{
            this.rules.batch_number[0].required=false
         }
      },
    /** 删除按钮操作 */
    handleDelete(index) {
        const _this=this;
        this.$confirm('是否确认删除?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            _this.have_sele_arr.splice(index,1);
            _this.form.batch_number='';
            _this.phlist=[];
        })
    },
    // 选择产品
    after_sele_Products(arr){
        // this.have_sele_arr=this.have_sele_arr.concat(arr)
        // 只能选一个
        this.have_sele_arr=arr;
        this.form.batch_number='';
        this.get_phdata(arr[0].product_id)
    },
    sele_products(){
        let ids=[]; // 已选择的id
        for(let i=0;i<this.have_sele_arr.length;i++){
            ids.push(this.have_sele_arr[i].id)
        }
        this.$refs['selectProducts'].init(ids,1,1)
    },
    
    // 表单重置
    reset() {
      this.form = {
        type:'2',
        halt_sale_date:this.today_val(),
        no:this.zjlist.length>0?this.zjlist[0].value:''
      };
      this.rules.batch_number[0].required=false;
      this.have_sele_arr=[];
      this.phlist=[];
      if(this.$refs['form']){
        this.$refs['form'].clearValidate();
      }
    },
    /** 提交按钮 */
    submitForm: function(form) {
      this.$refs[form].validate((valid)  => {
        if (valid) {
          if(form=='form'){
            let proarr=[];
            let _data={...this.form}
            if(this.have_sele_arr.length==0){
                this.$message.error('请选择产品');
                return false;
            }
            // 提交
            this.is_ajax = true;
            _data.product_id=this.have_sele_arr[0].product_id;
            this.$httpPost("/backend/HaltSaleRecord/save", _data).then((res) => {
                if (res.status == 200) {
                    this.$message.success(this.id ? '更新成功' : '新增成功');
                    if(this.id){
                        this.$router.go(-1)
                    }else{
                        // this.$nextTick(()=>{
                        //     this.reset();
                        // })
                        this.$router.go(-1)
                    }
                } else {
                    this.$message.error(res.message);
                }
                this.is_ajax = false;
            }).catch((err) => {
                console.log(err);
                this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data(){
        this.$httpGet("/backend/HaltSaleRecord/read", {id:this.id}).then((res) => {
            if (res.status == 200) {
                this.form=res.data;
                let pros=res.data.inventory_product;
                for(let i=0;i<pros.length;i++){
                    pros[i].id=pros[i].product_id;
                    pros[i].product_no=pros[i].product.product_no;
                    pros[i].product_name=pros[i].product.product_name;
                    pros[i].model=pros[i].product.model
                }
                this.have_sele_arr=pros;
                this.get_phdata(this.have_sele_arr[0].product_id)
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    
    get_phdata(id){
        this.$httpGet("/backend/ReportLossOverflow/productBatchNumber", {
            product_id: id
        }).then((res) => {
            if (res.status == 200) {
                this.phlist = res.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '停售产品'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(!this.id && this.zjlist.length>0){
                    this.form.no=this.zjlist[0].value
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
            if (res.status == 200) {
                this.list = res.data.department_list;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        this.$httpGet("/backend/sys.Admin/testList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.rylist = res.data.data;
                if(this.rylist.length>0 && !this.id){
                    this.form.test_id=this.rylist[0].id
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        this.$httpGet("/backend/sys.Admin/handleList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.jblist = res.data.data;
                if(this.jblist.length>0 && !this.id){
                    this.form.handle_id=this.jblist[0].id
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;
  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }
  .el-table {
    margin: 30px 0;
  }
}
.top_box {
  display: flex;
  justify-content: space-around;
}
.examin {
  display: flex;
  justify-content: space-between;
}
</style>